.features {
  position: relative;
  background-color: #FAF6F4;
  width: 100%;
  padding: 330px 0 74px 0;

  .features__line {
    width: 100%;
    position: absolute;
    top: 95px;
  }
}

.features__wrapper {
  margin: 0 auto;
  padding: 40px 0 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  max-width: 766px;

  .features__title {
    align-self: flex-start;
    margin: 0 0 48px 0;
  }

  .features__list {
    width: 100%;
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 32px;

    .features__item {
      display: flex;
      width: 200px;
      flex-direction: column;

      .feature__image {
        align-self: center;
        margin: 0 0 16px 0;
        max-width: 160px;
        max-height: 160px;
      }

      .feature__title {
        text-align: center;
        margin: 0 0 8px 0;
        font-family: 'Oxygen', sans-serif;
        font-style: normal;
        font-weight: 400;
      }

      .feature__text {
        margin: 0;
        text-align: center;
      }
    }
  }
}

@media (max-width: 1440px) {
  .features {
    .features__line {
      left: 50%;
      width: 1440px;
      transform: translateX(-50%);
    }
  }
}

@media (max-width: 1150px) {
  .features {
    .features__line {
      left: 58%;
    }
  }
}

@media (max-width: 1023px) {
  .features {
    .features__line {
      left: 72%;
    }

    .features__wrapper {
      padding: 0 16px;
    }
  }
}

@media (max-width: 767px) {
  .features {
    padding: 32px 16px;
  }

  .features__line {
    display: none;
  }

  .features__wrapper {
    max-width: 100%;
    padding: 0 16px 0;

    .features__title {
      margin-bottom: 32px;
    }

    .features__list {
      display: grid;
      grid-template-columns: 154px 154px;
      column-gap: 20px;
      row-gap: 24px;
      justify-content: space-around;

      .features__item {
        width: 154px;

        .feature__image {
          margin-bottom: 16px;

          img {
            aspect-ratio: 1 / 1;
            width: 154px;
            height: 154px;
          }
        }

        .feature__title {
          font-size: 18px;
          margin-bottom: 8px;
        }

        .feature__text {
          font-size: 14px;
          line-height: 170%;
        }
      }
    }
  }
}
