.social {
  display: flex;
  justify-self: end;
  gap: 16px;

  .social__link {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    border: none;
    border-radius: 50%;
    background-color: rgba(255, 255, 255, 0.2);

    &:hover, &:focus {
      box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
      background: rgba(255, 255, 255, 0.4);
      transition: all .4s ease-in-out;
    }

    &:active {
      box-shadow: 0 8px 30px rgba(0, 0, 0, 0.40);
      transition: all .4s ease-in-out;
    }
  }
}

@media (max-width: 767px) {
  .social {
    gap: 6px;
    align-self: center;
  }
}
