.jakarta {
  position: relative;
  background-color: #FAF6F4;
  width: 100%;
  padding: 80px 0 263px 0;
  margin: 0;
  box-sizing: border-box;

  .jakarta__line {
    z-index: 1;
    position: absolute;
    width: 100%;
    top: 260px;
  }
}

.jakarta__wrapper {
  box-sizing: border-box;
  position: relative;
  z-index: 2;
  margin: 0 auto;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: end;
  flex-direction: column;
  max-width: 766px;

  .jakarta__title {
    width: 470px;
    justify-self: start;
    margin: 0 0 16px 0;
  }

  .jakarta__content {
    width: 470px;
    justify-self: start;
    margin: 0 0 32px 0;
  }

  .jakarta__form-wrapper {
    width: 470px;
  }
}

@media (max-width: 1440px) {
  .jakarta {
    .jakarta__line {
      left: 50%;
      width: 1440px;
      transform: translateX(-50%);
    }
  }
}

@media (max-width: 1150px) {
  .jakarta {
    .jakarta__line {
      left: 60%;
    }
  }
}

@media (max-width: 1023px) {
  .jakarta {
    .jakarta__line {
      left: 72%;
    }

    .jakarta__wrapper {
      max-width: 100%;
      padding: 0 16px;
    }
  }
}

@media (max-width: 767px) {
  .jakarta {
    background-color: #F3EDE8;
    margin: 0;
    padding: 0 16px 32px 16px;

    .jakarta__wrapper {
      padding: 0;
      align-items: start;

      .jakarta__title {
        width: 100%;
        margin-bottom: 16px;
      }

      .jakarta__content {
        width: 100%;
      }

      .jakarta__form-wrapper {
        flex-direction: column;
        width: 100%;
        gap: 16px;
      }
    }
  }

  .jakarta__line {
    display: none;
  }
}

