.about-header {
  display: flex;
  width: 100%;
  background-color: #EE7B4C;
}

.about-header__wrapper {
  position: relative;
  margin: 0 auto;
  height: 540px;
  width: 940px;
  padding: 55px 0 0;
  display: flex;
  box-sizing: border-box;
  justify-content: start;

  .about-header__side {
    z-index: 2;
    width: 420px;
    display: flex;
    flex-direction: column;

    .about-header__title {
      width: 420px;
      display: block;
      font-size: 40px;
      line-height: 52px;
      color: #FFFFFF;
      margin: 0 0 24px 0;
    }

    .about-header__content {
      margin: 0 0 48px 0;
      display: block;
      color: rgba(255, 255, 255, 0.8);
    }

    .about-header__link {
      max-width: 310px;
      color: #EE7B4C;
      background-color: #FFFFFF;

      &:hover,
      &:focus {
        background-color: rgba(255, 253, 253, 0.5);
        color: #FFFFFF;
      }
    }
  }

  .blueBg {
    position: absolute;
    right: 62px;
    bottom: 0;
    width: 550px;
    z-index: 1;

    picture {
      display: block;
      right: 275px;
      opacity: 0;
      width: 100%;
      position: absolute;
      transform: translateX(50%);
      bottom: 0;

      &:after {
        z-index: 3;
        position: absolute;
        content: "";
        bottom: -155px;
        width: 612px;
        height: 612px;
        border-radius: 50%;
      }


      img {
        display: block;
        position: absolute;
        transform: translateX(-50%);
        bottom: 0;
        object-fit: contain;
        z-index: 4;
      }

      .about__image1 {
        height: 560px;
        left: 354px;
      }

      .about__image2 {
        height: 560px;
        left: 313px;
      }

      .about__image3 {
        height: 544px;
        left: 305px;
      }

    }

    .about__frame1:after {
      background-color: #A5B6E4;
    }

    .about__frame2:after {
      background-color: #FFBAA5;
    }

    .about__frame3:after {
      background-color: #FFD772;
    }
  }
}

.about-main {
  position: relative;
  z-index: 2;
  width: 100%;
  background-color: #FAF6F4;

  .about__line {
    position: absolute;
    transform: translateY(-100%);
    top: 103px;
    width: 100%;
  }
}

.about-main__wrapper {
  position: relative;
  margin: 0 auto;
  max-width: 766px;
  padding: 102px 0 0;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  justify-content: space-between;

  .about-main__title {
    margin: 0 0 24px 0;
  }

  .about-main__content {
    margin: 0 0 48px 0;
  }

  .about-main__list {
    padding: 0;
    margin: 0;
    display: grid;
    grid-template-columns: 50% 50%;
    row-gap: 32px;

    .list__title {
      position: relative;
      margin: 0 0 0 67px;
      font-size: 24px;
      line-height: 180%;
      color: rgba(0, 0, 0, 0.8);
      align-self: center;

      &:after {
        content: '';
        position: absolute;
        left: -36px;
        top: 50%;
        transform: translateY(-50%) translateX(-100%);
        width: 23px;
        height: 15px;
        background-image: url("../../../assets/Content/About/aboutArrow.svg");
      }
    }

    .list__description {
      width: 260px;
      margin: 0;
    }
  }
}

@media (max-width: 1440px) {
  .about-main {
    .about__line {
      left: 50%;
      right: 50%;
      width: 1440px;
      transform: translateY(-100%) translateX(-50%);
    }
}

@media (max-width: 1023px) {
  .about-header__wrapper {
    padding: 55px 16px 0;

    .blueBg {
      right: -23px;
    }
  }

    .about-main__wrapper {
      padding: 102px 16px 0;
      max-width: 100%;
    }
  }
}

@media (max-width: 767px) {
  .about-header__wrapper {
    padding: 0 16px 0 16px;
    height: auto;
    width: 100%;
    flex-direction: column;

    .about-header__side {
      width: 100%;
      padding: 22px 0 0 0;

      .about-header__title {
        width: 100%;
        font-size: 28px;
        line-height: 130%;
        text-align: start;
        margin: 0 0 8px 0;
      }

      .about-header__content {
        width: 100%;
        font-size: 16px;
        line-height: 130%;
        margin: 0 0 26px 0;
      }

      .about-header__link {
        max-width: 100%;
        margin: 0 0 40px 0;
      }
    }

    .blueBg {
      position: relative;
      width: 100%;
      right: 0;

      .about__frame1 {
        width: 100%;
        height: 347px;
        position: static;
        animation: none;
        opacity: 1;

        .about__image1 {
          width: 360px;
          height: 347px;
          left: 0;
          right: 0;
          bottom: 0;

        }

        &:after {
          width: 626px;
          height: 626px;
          left: -125px;
          bottom: 15px;
          overflow: hidden;
        }
      }

      .about__frame2,
      .about__frame3 {
        display: none;
      }
    }
  }

  .about__line {
    display: none;
  }

  .about-main {
    margin-top: -25px;
    background-color: #F3EDE8;
  }

  .about-main__wrapper {
    padding: 32px 16px 0 16px;
    height: auto;
    width: 100%;

    .about-main__title {
      margin: 0 0 8px 0;
    }

    .about-main__content {
      margin: 0 0 24px 0;
    }

    .about-main__list {
      grid-template-columns: 1fr;
      row-gap: 0;
      margin: 0;
      padding: 0;

      .list__title {
        font-size: 18px;
        margin: 0 0 18px 59px;

        &:after {
          left: -28px;
        }
      }

      .list__description {
        margin: 0 0 32px 0;
        width: 100%;
      }
    }
  }
}

// Animation settings
$totalTime: 60s;
$items: 3;
$animationSpeed: 10;

// Calculate animation time in seconds (how long the fade lasts)
$animationTime: 0s + $totalTime / ($items * $animationSpeed * 2);
// Calculate show time in seconds (how long the element is displayed)
$showTime: (0s + $totalTime - ($animationTime * $items)) / $items;

// Set animation for each element
@for $i from 1 through $items {
  .blueBg > *:nth-child(#{$i}) {
    // Delay is increased for each item
    // Starting with an offset of -$animationTime so the first element is displayed on load
    $delay: -$animationTime + ($animationTime + $showTime) * ($i - 1);
    animation: fadeinout $totalTime linear $delay infinite;
  }
}

// Calculate percentages of the display time for keyframes
$animationPercentage: 0% + 100 * (($animationTime / $totalTime));
$showPercentage: 0% + 100 * ($showTime / $totalTime);

@keyframes fadeinout {
  0% {
    opacity: 0;
  }
  #{$animationPercentage},
  #{$animationPercentage + $showPercentage} {
    opacity: 1;
  }
  #{$showPercentage + $animationPercentage * 2},
  100% {
    opacity: 0;
  }
}



