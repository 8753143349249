@font-face {
  font-family: 'Oxygen';
  src: url('../../assets/fonts/Oxygen/Oxygen-Regular.ttf') format('truetype');
}

.header {
  position: relative;
  z-index: 100;
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  width: 100%;
  max-width: 1168px;
  padding: 24px 24px 0;

  .header__logo {
    width: 93px;
    height: 93px;
    display: grid;
    place-content: center;
    cursor: pointer;
  }
}

@media (max-width: 1023px) {
  .header {
    padding: 19px 16px 0;
    grid-template-columns: 1fr 1fr;
  }
}
