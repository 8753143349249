.popup-overlay {
  background-color: rgba(0, 0, 0, 0.2)
}

.modal {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 85px 0 85px;
  background-color: #FAF6F4;
  border: none;
  box-shadow: 0 10px 40px rgba(0, 0, 0, 0.3);
  border-radius: 16px;
  box-sizing: border-box;
  min-width: 743px;

  .modal__close {
    border: none;
    padding: 0;
    margin: 0;
    top: 32px;
    right: 32px;
    position: absolute;
    width: 40px;
    height: 40px;
    background: center no-repeat url('../../assets/Modal/close.svg');
  }
}

.modal__title {
  z-index: 2;
  margin: 0 0 16px 0;
  padding: 0;
}

.modal__content {
  text-align: center;
  line-height: 130%;
  margin: 0 0 24px 0;
}

.modal__form {
  display: flex;
  flex-direction: column;

  .modal__input {
    width: 434px;
    background-color: #FFFFFF;
    margin-bottom: 16px;
    box-sizing: border-box;
    border: 1px solid transparent;
    height: 48px;
    border-radius: 16px;
    font-family: 'Oxygen', sans-serif;
    font-size: 18px;
    padding: 0 32px;
    flex-grow: 1;
    outline: none;
    color: #000000;

    &::placeholder {
      color: rgba(0, 0, 0, 0.2);
    }

    &:hover, &:focus, &:active {
      border: 1px solid rgba(0, 0, 0, 0.3);
    }
  }

  .errorInput {
    border: 1px solid rgba(228, 16, 16, 0.6);

    &:hover, &:focus, &:active {
      border: 1px solid rgba(228, 16, 16, 0.6);
    }
  }

  .modal__error {
    transition: 0.3s;
    font-size: 14px;
    line-height: 24px;
    color: #EF7070;
    margin: -12px 0 16px 32px;
  }
}

.modal__send {
  padding: 0 20px;
  margin-top: 16px;
  min-width: 310px;
  align-self: center;
}

.modal__success-btn {
  min-width: 310px;
}

.options__wrapper {
  overflow: visible;
  position: relative;
  color: rgba(0, 0, 0, 0.2);
  width: 434px;
  border: 1px solid transparent;
  box-sizing: border-box;
  background-color: #FFFFFF;
  margin-bottom: 16px;
  border-radius: 16px;

  &:hover, &:focus, &:active {
    border: 1px solid rgba(0, 0, 0, 0.3);
  }
}

.options__header {
  position: relative;
  display: flex;
  z-index: 10;
  align-items: center;
  height: 48px;
  font-size: 18px;
  box-sizing: border-box;
  padding: 0 32px;
  outline: none;
  color: rgba(0, 0, 0, 0.2);
}

.options__arrow {
  position: absolute;
  right: 32px;
  top: 50%;
  transform: translateY(-50%);
  width: 24px;
  height: 24px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  background: center transparent no-repeat url("../../assets/Content/Questions/arrow.svg");
  -webkit-tap-highlight-color: transparent;
}

.arrow__up {
  transform: translateY(-50%) rotate(180deg);
}

.options__list {
  z-index: 2;
  position: absolute;
  top: 0;
  left: 0;
  margin: 0;
  padding: 48px 0 0;
  list-style: none;
  display: flex;
  flex-direction: column;
  border-radius: 16px;
  box-shadow: 0 10px 40px rgba(0, 0, 0, 0.3);
}

.options__item {
  z-index: 3;
  width: 434px;
  background-color: #FFFFFF;
  box-sizing: border-box;
  border: none;
  height: 48px;
  font-family: 'Oxygen', sans-serif;
  font-size: 18px;
  padding: 12px 32px;
  flex-grow: 1;
  outline: none;
  color: #000000;

  &:last-child {
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
  }

  &:hover,
  &:focus,
  &:active {
    background-color: #F79772;
    color: #FFFFFF;
  }
}

.isOpened, .isOpened.errorInput {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;

  &:hover, &:focus, &:active {
    border: 1px solid transparent;
  }
}

.options__header-selected {
  color: black;
}

@media (max-width: 767px) {

  .popup-content {
    width: 100%;
    margin: 0 16px;
  }

  .modal {
    min-width: 328px;

    padding: 64px 24px 24px;
    box-sizing: border-box;
    margin: 0 16px;

    .modal__close {
      border: none;
      padding: 0;
      margin: 0;
      top: 24px;
      right: 24px;
      position: absolute;
      width: 24px;
      height: 24px;
      background: center no-repeat url('../../assets/Modal/close-mobile.svg');
    }
  }

  .modal__title {
    text-align: center;
    padding: 0;
  }

  .modal__content {
    max-width: 100%;
    margin-bottom: 32px;
  }

  .modal__form {
    width: 100%;

    .modal__input {
      width: 100%;
      padding: 12px 24px;
    }

    .modal__send {
      min-width: 100%;
    }

    .modal__error {
      margin: -12px 0 16px 24px;
    }
  }

  .modal__success-btn {
    min-width: 100%
  }

  .options__wrapper {
    width: 100%;
  }


  .options__header {
    padding: 12px 24px;
  }

  .options__list {
    width: 100%;
  }

  .options__item {
    width: 100%;
    padding: 12px 24px;
  }

  .options__arrow {
    right: 24px;
  }
}


