.bali {
  position: relative;
  background-color: #FAF6F4;
  width: 100%;
  padding: 90px 0 0 0;
  box-sizing: border-box;

  .bali__line {
    z-index: 1;
    position: absolute;
    width: 100%;
    top: -20px;
  }
}

.bali__wrapper {
  box-sizing: border-box;
  position: relative;
  z-index: 2;
  margin: 0 auto;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: start;
  flex-direction: column;
  max-width: 766px;

  .bali__title {
    margin: 0 0 16px 0;
  }

  .bali__content {
    position: relative;
    margin: 0 0 32px 0;
    color: rgba(0, 0, 0, 0.8)
  }

  .bali__link {
    width: 310px;
  }
}

@media (max-width: 1440px) {
  .bali {
    .bali__line {
      left: 50%;
      width: 1440px;
      transform: translateX(-50%);
    }
  }
}

@media (max-width: 1023px) {
  .bali {
    .bali__wrapper {
      max-width: 100%;
      padding: 0 16px;
    }
  }
}

@media (max-width: 767px) {
  .bali {
    background-color: #F3EDE8;
    padding: 32px 16px 48px 16px;

    .bali__wrapper {
      padding: 0;

      .bali__title {
        margin-bottom: 16px;
      }

      .bali__content {
        max-width: 100%;
        margin: 0 0 32px 0;
      }

      .bali__link {
        width: 100%;
      }
    }
  }

  .bali__line {
    display: none;
  }
}
