.navigation {
  .navigation__list {
    display: grid;
    grid-template-columns: max-content max-content max-content;
    grid-column-gap: 34px;
    justify-content: center;
    margin: 0;
    padding: 0;

    .navigation__item {
      margin: 0;
      padding: 0;
      list-style: none;

      .navigation__link {
        box-sizing: border-box;
        padding: 8px;
        display: block;
        position: relative;
        font-family: "Oxygen", sans-serif;
        font-size: 16px;
        font-weight: 400;
        font-style: normal;
        line-height: 23px;
        color: rgba(255, 255, 255, 0.8);
        text-decoration: none;

        &:hover, &:focus, &:active {
          color: rgba(255, 255, 255, 1);
        }
      }
    }
  }
}

@media (max-width: 1023px) {
    .navigation {
      display: none;
    }
}
