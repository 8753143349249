@font-face {
  font-family: 'Oxygen';
  src: url('/assets/fonts/Oxygen/Oxygen-Regular.ttf') format('truetype');
  font-display: swap;
}

@font-face {
  font-family: 'Oxygen-Bold';
  src: url('/assets/fonts/Oxygen/Oxygen-Bold.ttf') format('truetype');
  font-display: swap;
}

:root {
  --mobile: 360px;
  --tablet: 768px;
  --desktopMin: 1024px;
  --desktopMax: 1440px;
}

html,
body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  font-family: 'Oxygen', sans-serif;
  font-style: normal;
  color: rgba(0, 0, 0, 0.6);
  font-size: 16px;
  line-height: 170%;
  font-weight: 400;
  scroll-behavior: smooth;
  overflow-x: hidden;
  background: #EE7B4C;

  @media (max-width: 767px) {
    font-size: 14px;
  }
}

h1, h2, h3 {
  font-family: 'Oxygen-Bold', sans-serif;
  font-style: normal;
  font-weight: 700;
}

h2, h3 {
  font-size: 24px;
  line-height: 130%;
  color: rgba(0, 0, 0, 0.8);
}

body,
#root,
.App {
  height: 100%;
  width: 100%;
}

a:hover,
button:hover {
  cursor: pointer;
}

a:active,
button:active,
a:focus,
button:focus {
  outline: none;
}

a {
  transition: 0.3s;
}

.App {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
}

@media (max-width: 1440px) {
  .wrapper {
    overflow-x: hidden;
  }
}

.button {
  padding: 0 32px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  outline: none;
  height: 48px;
  border: none;
  border-radius: 30px;
  color: #FFFFFF;
  background-color: #EE7B4C;
  font-family: 'Oxygen-Bold', sans-serif;
  font-size: 18px;
  transition: all .4s ease-in-out;

  &:hover, &:focus {
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
    background-color: #F69772;
    transition: all .4s ease-in-out;
  }

  &:active {
    box-shadow: 0 8px 40px rgba(0, 0, 0, 0.40);
    transition: all .4s ease-in-out;
  }

  @media (max-width: 767px) {
    width: 100%;
  }
}

// Переносы в тексте
.br-mobile {
  display: none;
}

.br-tablet {
  display: none;
}

.br-desktop {
  display: inline;
}

@media (max-width: 1023px) {
  .br-tablet {
    display: inline;
  }
}


@media (max-width: 767px) {
  .br-mobile {
    display: inline;
  }

  .br-desktop {
    display: none;
  }
}
