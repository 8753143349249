.our-app {
  position: relative;
  background-color: #FAF6F4;
  width: 100%;
  padding: 192px 0 345px 0;
  box-sizing: border-box;

  .our-app__line {
    z-index: 1;
    position: absolute;
    width: 100%;
    bottom: -180px;
  }
}

.our-app__wrapper {
  box-sizing: border-box;
  position: relative;
  z-index: 2;
  margin: 0 auto;
  padding: 0 129px;
  display: flex;
  justify-content: center;
  align-items: start;
  flex-direction: column;
  max-width: 1256px;

  .our-app__title {
    position: relative;
    z-index: 2;
    width: 310px;
    justify-self: start;
    margin: 0 0 16px 0;
  }

  .our-app__content {
    position: relative;
    z-index: 2;
    width: 320px;
    justify-self: start;
    margin: 0 0 32px 0;
  }

  .our-app__form-wrapper {
    position: relative;
    z-index: 2;
    width: 410px;
    justify-self: start;
  }

  .our-app__interface {
    z-index: 1;
    left: 31%;
    top: -192px;
    position: absolute;
  }
}

@media (max-width: 1440px) {
  .our-app {
    .our-app__line {
      left: 50%;
      width: 1440px;
      transform: translateX(-50%);
    }

    .our-app__wrapper {
      .our-app__interface {
        left: 33%;
      }
    }
  }
}

@media (max-width: 1023px) {
  .our-app {
    .our-app__wrapper {
      max-width: 100%;
      padding: 0 16px;

      .our-app__form-wrapper {
        width: 410px;
      }

      .our-app__content {
        width: 410px;
      }

      .our-app__interface {
        left: 242px;
      }
    }
  }
}


@media (max-width: 767px) {
  .our-app {
    padding: 32px 16px;
    display: flex;
    flex-direction: column;

    .our-app__line {
      display: none;
    }

    .our-app__interface {
      position: static;
      margin: 0 -16px 24px 0;
      align-self: flex-end;

      img {
        height: auto;
        max-width: 100%;
      }
    }

    .our-app__wrapper {
      padding: 0;
      margin: 0;

      .our-app__title {
        width: 100%;
        margin: 0 0 16px 0;
      }

      .our-app__content {
        width: 100%;
        margin-bottom: 24px;
      }

      .our-app__form-wrapper {
        width: 100%;
      }
    }
  }
}
