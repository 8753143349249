.questions {
  position: relative;
  background-color: #FAF6F4;
  width: 100%;
  padding: 0 0 342px 0;
  box-sizing: border-box;

  .questions__line {
    z-index: 1;
    position: absolute;
    width: 100%;
    bottom: 22px;
  }
}

.questions__wrapper {
  box-sizing: border-box;
  position: relative;
  z-index: 2;
  margin: 0 auto;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  max-width: 766px;
  gap: 48px;

  .questions__title {
    margin: 0;
    text-align: center;
  }

  .questions__list {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
}

.question {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  background-color: #FFFFFF;
  border-radius: 16px;


  .question__wrapper {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: center;

    .question__title {
      padding: 21px 60px 21px 32px;
      flex-grow: 2;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      font-family: 'Oxygen-Bold', sans-serif;
      font-size: 18px;
      line-height: 130%;
      cursor: pointer;
    }

    .titleOpened {
      padding: 21px 60px 16px 32px;
    }

    .question__arrow {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      width: 73px;
      height: 73px;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      background: center transparent no-repeat url("../../../assets/Content/Questions/arrow.svg");
      -webkit-tap-highlight-color: transparent;
    }

    .arrow__up {
      transform: translateY(-50%) rotate(180deg);
    }
  }

  .question__content {
    text-align: start;
    padding: 0 32px 32px;
    font-family: 'Oxygen', sans-serif;
    font-style: normal;
    font-size: 18px;
    line-height: 130%;
    color: rgba(0, 0, 0, 0.6);
    animation: contentAnimation .5s ease-in-out;

    @keyframes contentAnimation {
      0% {
        opacity: 0
      }
      100% {
        opacity: 1
      }
    }
  }
}

@media (max-width: 1440px) {
  .questions {
    .questions__line {
      left: 50%;
      width: 1440px;
      transform: translateX(-50%);
    }
  }
}


@media (max-width: 1150px) {
  .questions {
    .questions__line {
      left: 53%;
    }
  }
}


@media (max-width: 1023px) {
  .questions {
    .questions__line {
      left: 69%;
    }

    .questions__wrapper {
      max-width: 100%;
      padding: 0 16px;
    }
  }
}

@media (max-width: 767px) {
  .questions {
    padding: 32px 16px 32px;

    .questions__line {
      display: none;
    }

    .questions__wrapper {
      padding: 0;
      margin: 0;
      gap: 32px;

      .questions__title {
        width: 100%;
      }
    }
  }

  .question {
    border-radius: 16px;

    .question__wrapper {

      .question__title {
        font-size: 14px;
        padding: 32px 55px 32px 24px;
      }

      .titleOpened {
        padding: 32px 55px 16px 24px;
      }

      .question__arrow {
        width: 72px;
        height: 72px;
        background-image: url("../../../assets/Content/Questions/arrow-mobile.svg");
      }
    }

    .question__content {
      font-size: 14px;
      padding: 0 24px 32px;
    }
  }
}

