.emailForm {
  width: 100%;
  position: relative;
  justify-self: start;
  display: flex;
  gap: 13px;

  .emailForm__input {
    background-color: #FFFFFF;
    border: 1px solid transparent;
    height: 48px;
    box-sizing: border-box;
    border-radius: 16px;
    -moz-border-radius: 16px;
    -webkit-border-radius: 16px;
    transform: translateZ(0);
    font-family: 'Oxygen', sans-serif;
    font-size: 18px;
    padding: 0 32px;
    flex-grow: 1;
    outline: none;
    color: #000000;

    &::placeholder {
      color: rgba(0, 0, 0, 0.2);
    }

    &:hover, &:focus, &:active {
      border: 1px solid rgba(0, 0, 0, 0.3);
    }
  }

  .errorActive {
    border: 1px solid rgba(228, 16, 16, 0.6);

    &:hover, &:focus, &:active {
      border: 1px solid rgba(228, 16, 16, 0.6);
    }
  }

  .emailForm__error {
    position: absolute;
    bottom: -28px;
    left: 32px;
    transition: 0.3s;
    text-align: left;
    font-size: 14px;
    line-height: 24px;
    color: #EF7070;
    font-family: 'Oxygen', sans-serif;
    opacity: 1;
  }
}

@media (max-width: 767px) {

  .emailForm {
    flex-direction: column;
    width: 100%;
    gap: 0;

    .emailForm__input {
      margin-bottom: 16px;
    }

    .errorActive {
      margin-bottom: 16px;
    }

    .emailForm__error {
      position: static;
      margin: -12px 0 16px 32px;
    }
  }
}
