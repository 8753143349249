.footer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  box-sizing: border-box;
  width: 100%;
  max-width: 1168px;
  padding: 28px 24px;
  gap: 4px;

  .footer__main {
    display: grid;
    grid-template-columns: 1fr auto 1fr;
    align-items: center;
    width: 100%;

    .footer__logo {
      width: 93px;
      height: 93px;
      display: grid;
      place-content: center;
      cursor: pointer;
    }
  }

  .copyright {
    display: flex;
    justify-content: flex-start;

    .copyright__content {
      margin: 0;
      padding: 0;
      font-size: 14px;
      color: rgba(255, 255, 255, 0.8);
    }
  }
}


@media (max-width: 1023px) {
  .footer {
    padding: 11px 16px 16px;

    .footer__main {
      grid-template-columns: 1fr 1fr;
    }

  }
}

@media (max-width: 767px) {
  .footer {
    padding: 11px 16px 16px;
    gap: 5px;

    //.footer__main {
    //  grid-template-columns: 1fr 1fr;
    //}

    .copyright {
      align-self: center;
    }
  }
}
