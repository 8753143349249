.partnership {
  position: relative;
  background-color: #FAF6F4;
  width: 100%;
  padding: 190px 0 319px 0;
  box-sizing: border-box;

  .partnership__line {
    z-index: 1;
    position: absolute;
    width: 100%;
    bottom: -20px;
  }

  .partnership__wrapper {
    position: relative;
    z-index: 2;
    margin: 0 auto;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: end;
    flex-direction: column;
    max-width: 998px;
    box-sizing: border-box;

    .partnership__bubbles {
      z-index: 1;
      left: 0;
      position: absolute;
    }

    .partnership__title {
      width: 44%;
      justify-self: start;
      margin: 0 0 16px 0;
    }

    .partnership__content {
      width: 44%;
      justify-self: start;
      margin: 0 0 32px 0;
      padding-right: 26px;
      box-sizing: border-box;
    }

    .partnership__btn-wrapper {
      width: 44%;
      justify-self: start;

      .partnership__btn {
        min-width: 310px;
      }
    }
  }
}


@media (max-width: 1440px) {
  .partnership {
    .partnership__line {
      left: 50%;
      width: 1440px;
      transform: translateX(-50%);
    }
  }
}

@media (max-width: 1023px) {
  .partnership {
    .partnership__wrapper {
      max-width: 100%;
      padding: 0 16px;

      .partnership__bubbles {
        left: -154px;
      }
      .partnership__title {
        width: 48%;
      }
      .partnership__content {
        width: 48%;
        padding-right: 0;
      }
      .partnership__btn-wrapper {
        width: 48%;
      }

    }
  }
}

@media (max-width: 767px) {
  .partnership {
    padding: 32px 16px;
    background-color: #F3EDE8;
    display: flex;
    flex-direction: column;

    .partnership__line {
      display: none;
    }

    .partnership__wrapper {
      padding: 0;
      margin: 0;

      .partnership__bubbles {
        position: static;
        margin: 0 0 26px 0;
        align-self: flex-start;

        img {
          width: 100%;
          height: auto;
        }
      }

      .partnership__title {
        width: 100%;
        margin-bottom: 16px;
      }

      .partnership__content {
        width: 100%;
      }

      .partnership__btn-wrapper {
        width: 100%;

        .partnership__btn {
          min-width: 100%;
        }
      }
    }
  }
}
